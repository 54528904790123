import { useAuthStore } from '@/stores/auth';
import { useDashboardUserStore } from '@/stores/dashboardUser';
import { useApiTokensStore } from '@/stores/apiTokens';

function resetStateForLogout() {
    const authStore = useAuthStore();
    authStore.resetState();

    const dashboardUserStore = useDashboardUserStore();
    dashboardUserStore.resetState();

    const apiTokensStore = useApiTokensStore();
    apiTokensStore.resetState();
}

export { resetStateForLogout };
