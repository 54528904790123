import { defineStore } from 'pinia';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import type { ApiToken } from '@/types/types';

const baseUrl = `${
    import.meta.env.VITE_ACE360_CONNECT_API_URL
}/users/{userId}/api-tokens`;

export const useApiTokensStore = defineStore({
    id: 'apiTokens',
    state: () => ({
        apiTokens: null as ApiToken[] | null,
    }),
    actions: {
        async getApiTokensByUserId(userId: number) {
            const requestUrl = baseUrl.replace('{userId}', userId.toString());
            fetchWrapper
                .get(requestUrl)
                .then((response) => (this.apiTokens = response.tokens))
                // eslint-disable-next-line
                .catch((error) => console.log(error));
        },
        async createApiToken(userId: number, tokenName: string) {
            const requestUrl = `${
                import.meta.env.VITE_ACE360_CONNECT_API_URL
            }/users/${userId}/api-token`;
            return (
                fetchWrapper
                    .post(requestUrl, { tokenName })
                    .then((response) => {
                        return response;
                    })
                    // eslint-disable-next-line
                    .catch((error) => console.log(error))
            );
        },
        async deleteApiToken(userId: number, tokenId: number) {
            const requestUrl = `${
                import.meta.env.VITE_ACE360_CONNECT_API_URL
            }/users/${userId}/api-tokens/${tokenId}`;
            return (
                fetchWrapper
                    .delete(requestUrl)
                    .then((response) => response)
                    // eslint-disable-next-line
                    .catch((error) => console.log(error))
            );
        },
        resetState() {
            this.apiTokens = null;
        },
    },
});
