const AuthRoutes = {
    path: '/auth',
    component: () => import('@/layouts/blank/BlankLayout.vue'),
    meta: {
        requiresAuth: false,
    },
    children: [
        {
            name: 'Boxed Login',
            path: '/auth/login',
            component: () => import('@/views/authentication/BoxedLogin.vue'),
        },
        {
            name: 'Boxed Forgot Password',
            path: '/auth/forgot-password',
            component: () =>
                import('@/views/authentication/BoxedForgotPassword.vue'),
        },
        {
            name: 'Boxed Two Steps',
            path: '/auth/two-step',
            component: () => import('@/views/authentication/BoxedTwoStep.vue'),
            props: true,
        },
        {
            name: 'Error',
            path: '/auth/404',
            component: () => import('@/views/authentication/Error.vue'),
        },
        {
            name: 'Maintenance',
            path: '/auth/maintenance',
            component: () => import('@/views/authentication/Maintenance.vue'),
        },
    ],
};

export default AuthRoutes;
