import { createRouter, createWebHistory } from 'vue-router';
import MainRoutes from './MainRoutes';
import AuthRoutes from './AuthRoutes';
import MaintenanceRoute from './MaintenanceRoute';
import CatchAllRoute from './CatchallRoute';
import { useAuthStore } from '@/stores/auth';

const routes = [];
const isMaintenanceMode = import.meta.env.VITE_MAINTENANCE_MODE === 'true';
if (isMaintenanceMode) {
    routes.push(MaintenanceRoute);
} else {
    routes.push(AuthRoutes);
    routes.push(MainRoutes);
    routes.push(CatchAllRoute);
}

export const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (isMaintenanceMode && to.path !== '/') {
        next('/');
        return;
    }

    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/auth/login'];
    const authRequired = !publicPages.includes(to.path);
    const auth: any = useAuthStore();

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (authRequired && !auth.user) {
            auth.returnUrl = to.fullPath;
            return next('/auth/login');
        } else next();
    } else {
        next();
    }
});
