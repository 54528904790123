import { router } from '@/router';
import type { User } from '@/types/types';
export { goToDashboard };

function goToDashboard(user: User) {
    const isFisssUser = user.organisation.organisationType === 'fisss';
    const adminDashboardRoute = '/admin/overview';
    const clientDashboardRoute = `/client/${user.id}/overview`;
    router.push(isFisssUser ? adminDashboardRoute : clientDashboardRoute);
}
