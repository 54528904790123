import { defineStore } from 'pinia';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import type { User } from '@/types/types';

const baseUrl = `${import.meta.env.VITE_ACE360_CONNECT_API_URL}/user/`;

export const useDashboardUserStore = defineStore({
    id: 'DashboardUser',
    state: () => ({
        user: null as User | null,
    }),
    actions: {
        async getUser(userId: number) {
            fetchWrapper
                .get(baseUrl + userId)
                .then((user) => (this.user = user))
                // eslint-disable-next-line
                .catch((error) => console.log(error));
        },
        resetState() {
            this.user = null;
        },
    },
});
