import { defineStore } from 'pinia';
import { router } from '@/router';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import { goToDashboard } from '@/utils/helpers/goToDashboard';
import { isAllowed } from '@/utils/helpers/isAllowed';
import { resetStateForLogout } from '@/utils/helpers/resetStateForLogout';

const baseUrl = `${import.meta.env.VITE_ACE360_CONNECT_API_URL}`;
const webAppBaseUrl = `${import.meta.env.VITE_ACE360_WEB_APP_API_URL}`;

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        // eslint-disable-next-line
        // @ts-ignore
        user: JSON.parse(localStorage.getItem('user')),
        returnUrl: null,
    }),
    actions: {
        async login(email: string, password: string, hcaptcha: string) {
            const user = await fetchWrapper.post(
                `${webAppBaseUrl}/auth?includes=organisation`,
                {
                    email,
                    password,
                    hcaptcha,
                }
            );

            if (user.twoFactorMethod) {
                router.push({
                    name: 'Boxed Two Steps',
                    params: {
                        userId: user.userId,
                        twoFactorMethod: user.twoFactorMethod,
                    },
                });

                return;
            }

            if (!isAllowed(user.user)) {
                return Promise.reject(
                    "ACE360 Connect is not enabled for this user's organisation."
                );
            }

            // update pinia state
            this.user = user;
            // store user details and jwt in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            goToDashboard(user.user);
        },
        async twoFactor(
            code: string,
            recoveryCode: string | null,
            userId: number
        ) {
            await fetchWrapper
                .post(`${webAppBaseUrl}/twofactor?includes=organisation`, {
                    code,
                    recoveryCode,
                    userId,
                })
                .then((res) => {
                    if (!isAllowed(res.user)) {
                        return Promise.reject(
                            'ACE360 Connect is not enabled for your organisation.'
                        );
                    }

                    this.user = res;
                    localStorage.setItem('user', JSON.stringify(res));

                    goToDashboard(res.user);
                })
                .catch((err) => {
                    return Promise.reject(err);
                });
        },
        logout() {
            resetStateForLogout();
            localStorage.removeItem('user');
            router.push('/');
        },
        resetState() {
            this.user = null;
        },
    },
});
